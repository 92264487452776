<template>
  <div
    class="disclaimer"
    v-html="disclaimer"
  />
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'Disclaimer',
    computed: {
      ...mapGetters('cl/misc', ['getMisc']),
      disclaimer () {
        return this.getMisc?.disclaimer
      }
    }
  }
</script>

<style lang="scss" scoped>
  .disclaimer {
    :deep(p) {
      @include type-body-s;

      color: color(black);

      &:last-child {
        margin: 0;
      }
    }
  }
</style>
